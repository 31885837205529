import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import Papa from "papaparse";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";

import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#fff"
  },

  tabmsg: {
    backgroundColor: theme.palette.campaigntab,
  },

  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  selectChipsContainer: {
		display: "flex",
		flexWrap: "wrap",
  },

  chip: {
    margin: 2,
  }
}));

const CampaignSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Obrigatório")
});

const initialState = {
  name: "",
  type: "charge",
  businessIds: [],
  costCenterId: ""
}

const CUSTOM_VARIABLES_QUANTITY = 15;

const EasyConnectModal = ({
  open,
  onClose,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const [campaign, setCampaign] = useState(initialState);

  const [businesses, setBusinesses] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  
  const fileRef = useRef(null);
  const [file, setFile] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [isReadingFile, setIsReadingFile] = useState(false);
  const [fileError, setFileError] = useState();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      api.get('/nexusCostCenters').then(({ data }) => setCostCenters(data));
      api.get('/nexusUsers').then(({ data }) => setBusinesses(data));
    }
  }, [open]);
  
  const handleClose = () => {
    onClose();
    setCampaign(initialState);
    setFile(null);
    setCustomFields([]);
    setFileError();
    fileRef.current.value = null;
  };

  const handleAttachmentFile = async (e) => {
    setFile(null);
    setCustomFields([]);
    setFileError(null);
    setIsReadingFile(true);

    try {
      const file = e.target.files[0];
      if (!file) return;

      const fileType = file.name.split('.').pop() || '';
      const acceptedFileTypes = ['csv'];

      if (!acceptedFileTypes.includes(fileType)) {
        setFileError("Arquivo inválido. Por favor, selecione um arquivo .csv.");
        return;
      }

      const fileContent = await new Promise((resolve, reject) => {
        Papa.parse(file, { 
          header: true,
          delimiter: ";",
          skipEmptyLines: true,
          complete: (results) => {
            if (results.errors.length) {
              reject(results.errors);
            } else {
              resolve(results.data);
            }
          },
        });
      })

      if (fileContent.length === 0) {
        setFileError("Arquivo vazio. Por favor, selecione um arquivo com dados.");
        return;
      }

      const requiredFields = ["telefone", "nome", "cpf"];
      const optionalFields = Array(CUSTOM_VARIABLES_QUANTITY).fill("").map((_, index) => `custom_${index + 1}`);
      
      const hasRequiredFields = requiredFields.every((field, index) => Object.keys(fileContent[0])[index] === field);
      const hasInvalidFields = Object.keys(fileContent[0]).some(field => !requiredFields.includes(field) && !optionalFields.includes(field));

      if (!hasRequiredFields || hasInvalidFields) {
        setFileError("Arquivo inválido. Por favor, selecione um arquivo .csv com o formato correto.");
        return;
      }

      const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50mb

      if (file.size > MAX_FILE_SIZE) {
        setFileError("O arquivo não pode exceder 50MB.");
        return;
      }

      const customFields = Object.keys(fileContent[0])
        .map((field, index) => ([field, index]))
        .filter(([field]) => optionalFields.includes(field));

      setFile(file);
      setCustomFields(customFields);
    } catch (err) {
      console.error(err);
      setFileError("Erro ao ler o arquivo. Por favor, tente novamente.");
    } finally {
      setIsReadingFile(false);
    }
  };

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      if (fileError) {
        return;
      }

      if (!file) {
        setFileError("Por favor, selecione um arquivo.");
        return;
      }

      if (!values.businessIds.length) {
        actions.setFieldError("businessIds", "Selecione uma ou mais empresas.");
        return;
      }

      if (!values.costCenterId) {
        actions.setFieldError("costCenterId", "Selecione um centro de custo.");
        return;
      }

      const matrix_variables = customFields
        .map(([field, index]) => ({ file_index: index, template_variable: field }))

      const data = {
        title: values.name,
        filename: file.name,
        cost_center_id: values.costCenterId,
        business_ids: JSON.stringify(values.businessIds),
        matrix_variables,
      };

      const campaignResponse = await api.post("/nexusCampaigns", data);

      await axios({
        method: 'PUT',
        url: campaignResponse.data.upload_url,
        data: file,
        headers: { 'Content-Type': 'text/csv' },
      });

      toast.success("Campanha salva com sucesso.");
      handleClose();
      onSave();
    } catch (err) {
      console.error(err);
      toast.error("Erro ao salvar campanha. Por favor, tente novamente.");
    } finally {
      actions.setSubmitting(false);
    }
  }

  const handleDownloadExample = () => {
    const exampleData = [["telefone", "nome", "cpf"].concat(Array(CUSTOM_VARIABLES_QUANTITY).fill("").map((_, index) => `custom_${index + 1}`))];
    const csv = Papa.unparse(exampleData, { delimiter: ";" });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "exemplo.csv";
    link.click();
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Nova Campanha
        </DialogTitle>
        <Formik
          initialValues={campaign}
          enableReinitialize={true}
          validationSchema={CampaignSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Grid spacing={2} container>
                  <Grid xs={12} md={8} item>
                    <Field
                      as={TextField}
                      label={"Nome"}
                      id="name"
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      variant="outlined"
                      margin="none"
                      size="small"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <FormControl
                      variant="outlined"
                      margin="none"
                      size="small"
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel id="type-selection-label">
                        Tipo
                      </InputLabel>
                      <Field
                        as={Select}
                        label="Tipo"
                        placeholder="Tipo"
                        labelId="type-selection-label"
                        id="type"
                        name="type"
                        error={touched.type && Boolean(errors.type)}
                        disabled
                      >
                        <MenuItem value="charge">Cobrança</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} item>
                    <Box 
                      border="1px dashed"
                      borderColor="grey.400"
                      borderRadius={4}
                      padding={1.5}
                    >
                      <div style={{ display: "none" }}>
                        <input
                          accept=".csv"
                          type="file"
                          ref={fileRef}
                          onChange={(e) => handleAttachmentFile(e)}
                        />
                      </div>
                      <Field
                        as={TextField}
                        label={"Arquivo"}
                        id="file"
                        name="file"
                        error={!!fileError}
                        helperText={fileError}
                        variant="outlined"
                        margin="none"
                        size="small"
                        fullWidth
                        value={file?.name || ''}
                        className={classes.textField}
                        onClick={() => fileRef.current.click()}
                        disabled={isReadingFile}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              {isReadingFile ? (
                                <CircularProgress size={20} />
                              ): (
                                <BackupOutlinedIcon />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      
                      <Box marginTop={1}>
                        <Typography variant="caption" display="block">
                          - Extensões permitidas: <strong>.csv</strong>
                        </Typography>
                        <Typography variant="caption" display="block">
                          - Tamanho máximo: <strong>50mb</strong>
                        </Typography>
                        <Typography variant="caption" display="block">
                          - Cabeçalho <strong>(header)</strong> obrigatório
                        </Typography>
                        <Typography variant="caption" display="block">
                          - Colunas: <strong>telefone;nome;cpf</strong>
                        </Typography>
                        <Typography variant="caption" display="block">
                          - Separador de colunas: <strong>; (ponto e vírgula)</strong>
                        </Typography>
                      </Box>
                      
                      <Box marginTop={1}>
                        <Button 
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleDownloadExample}
                        >
                          Download exemplo .csv
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <FormControl
                      variant="outlined"
                      margin="none"
                      size="small"
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel id="business-selection-label">
                        Empresa
                      </InputLabel>
                      <Field
                        as={Select}
                        label={'Empresa'}
                        placeholder={'Empresa'}
                        labelId="business-selection-label"
                        id="businessIds"
                        name="businessIds"
                        error={touched.businessIds && Boolean(errors.businessIds)}
                        disabled={!file}
                        multiple
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        renderValue={selected => (
                          <div className={classes.selectChipsContainer}>
                            {selected.map(id => {
                              const business = businesses.find(b => b.id === id);
                              const firstName = business.name.split(' ')[0];
                              return business ? (
                                <Chip
                                  key={id}
                                  label={firstName}
                                  className={classes.chip}
                                />
                              ) : null;
                            })}
                          </div>
                        )}
                      >
                        {businesses?.map((business) => (
                          <MenuItem key={business.id} value={business.id}>
                            {business.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <FormControl
                      variant="outlined"
                      margin="none"
                      size="small"
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel id="cost-center-selection-label">
                        Centro de Custo
                      </InputLabel>
                      <Field
                        as={Select}
                        label={'Centro de Custo'}
                        placeholder={'Centro de Custo'}
                        labelId="cost-center-selection-label"
                        id="costCenterId"
                        name="costCenterId"
                        error={touched.costCenterId && Boolean(errors.costCenterId)}
                        disabled={!file}
                      >
                        {costCenters?.map((costCenter) => (
                          <MenuItem key={costCenter.id} value={costCenter.id}>
                            {costCenter.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  Fechar
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  Salvar
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default EasyConnectModal;
