import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import EasyConnectModal from "../../components/EasyConnectModal";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_CAMPAIGNS") {
    const campaigns = action.payload;
    const newCampaigns = [];

    campaigns.forEach((campaign) => {
      const campaignIndex = state.findIndex((s) => s.id === campaign.id);
      if (campaignIndex !== -1) {
        state[campaignIndex] = campaign;
      } else {
        newCampaigns.push(campaign);
      }
    });

    return [...newCampaigns, ...state];
  }

  if (action.type === "UPDATE_CAMPAIGN") {
    const tag = action.payload;
    const tagIndex = state.findIndex((s) => s.id === tag.id);

    if (tagIndex !== -1) {
      state[tagIndex] = tag;
      return [...state];
    } else {
      return [tag, ...state];
    }
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const EasyConnect = () => {
  const classes = useStyles();
  
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [campaigns, dispatch] = useReducer(reducer, []);

  const [searchParam, setSearchParam] = useState("");
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const [processingCampaign, setProcessingCampaign] = useState(null);

  const fetchCampaigns = useCallback(async () => {
    try {
      const { data } = await api.get("/nexusCampaigns");

      const statusMap = {
        ACTIVE: "Ativa",
        FINISHED: "Finalizada",
        MISSING_FILE: "Analisando",
        ANALYZING: "Analisando",
      }

      const campaigns = data.results.map((campaign) => ({
        ...campaign,
        displayStatus: statusMap[campaign.status] || campaign.status,
      }));

      dispatch({ type: "LOAD_CAMPAIGNS", payload: campaigns });
      // setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(async () => {
      await fetchCampaigns();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchCampaigns]);

  const handleOpenCampaignModal = () => {
    setCampaignModalOpen(true);
  };

  const handleCloseCampaignModal = () => {
    setCampaignModalOpen(false);
  };

  const handleSaveCampaign = () => {
    handleCloseCampaignModal();
    fetchCampaigns();
  }

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  // const loadMore = () => {
  //   setPageNumber((prevState) => prevState + 1);
  // };

  // const handleScroll = (e) => {
  //   if (!hasMore || loading) return;
  //   const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
  //   if (scrollHeight - (scrollTop + 100) < clientHeight) {
  //     loadMore();
  //   }
  // };

  const handleTriggerCampaign = async (campaign) => {
    if (processingCampaign) return;
    if (campaign.status !== 'ACTIVE') return; 

    setProcessingCampaign(campaign);
    try {
      await api.post("/nexusTriggerCampaign", {
        nexusCampaignId: campaign.id
      });

      toast.success("Campanha iniciada com sucesso!");

      fetchCampaigns();
    } catch (err) {
      toastError(err);
    } finally {
      setProcessingCampaign(null);
    }
  }

  return (
    <MainContainer>
      <EasyConnectModal
        open={campaignModalOpen}
        onClose={handleCloseCampaignModal}
        onSave={handleSaveCampaign}
        aria-labelledby="form-dialog-title"
      />

      <MainHeader>
        <Title>{i18n.t("easyConnect.title")}</Title>
        <MainHeaderButtonsWrapper>
          {/* <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          /> */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCampaignModal}
          >
            {i18n.t("easyConnect.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        // onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{i18n.t("easyConnect.table.campaign")}</TableCell>
              <TableCell>{i18n.t("easyConnect.table.status")}</TableCell>
              <TableCell align="center" width="150px">{i18n.t("easyConnect.table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {campaigns.map((campaign) => (
                <TableRow key={campaign.id}>
                  <TableCell>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="subtitle2">{campaign.title}</Typography>
                      <Typography variant="body2">{campaign.created_at}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{campaign.displayStatus}</TableCell>
                  <TableCell align="center">
                    {campaign.status === 'FINISHED' ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <IconButton
                        onClick={() => handleTriggerCampaign(campaign)}
                        disabled={(campaign.status !== 'ACTIVE' || !!processingCampaign)}
                        style={{ opacity: (campaign.status !== 'ACTIVE' || !!processingCampaign) ? 0.3 : 1 }}
                      >
                        {processingCampaign?.id === campaign.id ? (
                          <CircularProgress size={20} />
                        ) : (
                          <PlayCircleFilledIcon color="primary" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default EasyConnect;
