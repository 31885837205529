import { useContext, useMemo } from "react";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext.js";

const useWhatsApps = (whatsappId) => {
  const { whatsApps } = useContext(WhatsAppsContext)
  const whatsapp = useMemo(() => {
    if (!whatsappId) return null;
    return whatsApps.find((w) => w.id === whatsappId) || null;
  }, [whatsApps, whatsappId]);
  return whatsapp;
};

export default useWhatsApps;
