import React, { useState, useRef } from "react";
import Papa from "papaparse";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { green } from "@material-ui/core/colors";
import api from "../../services/api";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    explanation: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}));

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 1MB

const ContactFileImportModal = ({ open, onClose, setOpen, fetchContacts }) => {
    const classes = useStyles();
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile.size > MAX_FILE_SIZE) {
            setError("O arquivo não pode exceder 5MB.");
            setFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        } else {
            setFile(selectedFile);
            setError(null);
        }
    };

    const handleFileUpload = () => {
        if (!file) {
            setError("Por favor, selecione um arquivo.");
            return;
        }

        setLoading(true);
        Papa.parse(file, {
            header: true,
            delimiter: ";",
            complete: async (results) => {
                setLoading(false);
                console.log(results)
                const { data, errors } = results;
                if (errors.length) {
                    setError("Erro ao processar o arquivo.");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                } else {
                    const isValid = validateCSVLayout(data);
                    if (isValid) {
                        try {
                            const formData = new FormData();
                            formData.append("file", file);
                            await api.post("/upload", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            });
                            setError(null);
                            setOpen(false);
                            fetchContacts();
                            toast.success("Contatos importados com sucesso.");
                        } catch (apiError) {
                            setError("Erro ao enviar os dados para a API.");
                        }
                    } else {
                        setError("Layout do arquivo inválido. Esperado: nome, numero, telefone, cpf.");
                        if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                        }
                    }
                }
            },
        });
    };

    const validateCSVLayout = (data) => {
        if (data.length === 0) return false;
        const keys = Object.keys(data[0]);
        return keys.includes("nome") && keys.includes("telefone") && keys.includes("cpf") && keys.includes("email");
    };

    const handleDownloadExample = () => {
        const exampleData = [
            ["nome", "telefone", "cpf", "email"],
        ];
        const csv = Papa.unparse(exampleData, { delimiter: ";" });
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "exemplo.csv";
        link.click();
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <DialogTitle id="form-dialog-title">Importar Contatos</DialogTitle>
                <DialogContent dividers>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ marginTop: 20 }}
                    />
                    {error && <Typography color="error" style={{ marginTop: 10 }}>{error}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleFileUpload}
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        className={classes.btnWrapper}
                    >
                        Upload
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                    <Button onClick={handleDownloadExample} color="default" variant="contained">
                        Download Exemplo CSV
                    </Button>
                </DialogActions>
                <Paper className={classes.explanation} elevation={3}>
                    <Typography variant="body1">O arquivo deve ter o seguinte layout (csv com ponto e virgula):</Typography>
                    <br />
                    <Typography variant="body2">nome;telefone;cpf;email</Typography>
                </Paper>
            </Dialog>
        </div>
    );
};

export default ContactFileImportModal;
