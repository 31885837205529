import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import HistoryIcon from "@material-ui/icons/History";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PauseIcon from "@material-ui/icons/Pause";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import NewTicketFasterModal from "../NewTicketFasterModal";
import TicketsList from "../TicketsListCustom";
import TicketsListIndexed from "../TicketsListIndexed";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button } from "@material-ui/core";
import { TagsFilter } from "../TagsFilter";
import { UsersFilter } from "../UsersFilter";
import { TicketOriginsFilter } from "../TicketOriginsFilter";
import { queryTickets } from "../../services/db";

const useStyles = makeStyles(theme => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRadius: 0,
  },
  openTicketsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  ticketTabsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  tabsHeader: {
    flex: "none",
    backgroundColor: theme.palette.tabHeaderBackground,
  },
  tabsInternal: {
    flex: "none",
    backgroundColor: theme.palette.tabHeaderBackground
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    width: "auto",
    minWidth: "auto",
    minHeight: '64px',
  },

  internalTab: {
    minWidth: 120,
    width: 120,
    padding: 5
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.optionsBackground,
    padding: theme.spacing(1),
  },

  ticketSearchLine: {
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: theme.palette.total,
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  insiderTabPanel: {
    height: '100%',
    marginTop: "-72px",
    paddingTop: "72px"
  },

  insiderDoubleTabPanel: {
    display: "flex",
    flexDirection: "column",
    marginTop: "-72px",
    paddingTop: "72px",
    height: "100%"
  },

  labelContainer: {
    width: "auto",
    padding: 0
  },
  iconLabelWrapper: {
    flexDirection: "row",
    '& > *:first-child': {
      marginBottom: '3px !important',
      marginRight: 16
    }
  },
  insiderTabLabel: {
    [theme.breakpoints.down(1600)]: {
      display: 'none'
    }
  },
  smallFormControl: {
    '& .MuiOutlinedInput-input': {
      padding: "12px 10px",
    },
    '& .MuiInputLabel-outlined': {
      marginTop: "-6px"
    }
  }
}));

const TicketsManagerTabs = () => {
  const classes = useStyles();
  const history = useHistory();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const { profile } = user;

  const userQueueIds = user.queues?.filter(q => q.name !== 'ARW' && q.name !== 'ARO' && q.name !== 'BOT').map(q => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedOrigins, setSelectedOrigins] = useState([]);

  const searchQueueIds = useMemo(() => {
    return user?.queues?.map(q => q.id) || [];
  }, [user]);

  const openCount = useLiveQuery(() => {
    return queryTickets({ status: 'open', showAll: showAllTickets, userId: user?.id, selectedQueueIds }).count()
  }, [showAllTickets, selectedQueueIds], 0);

  const pendingCount = useLiveQuery(() => {
    return queryTickets({ status: 'pending', showAll: showAllTickets, userId: user?.id, selectedQueueIds }).count()
  }, [showAllTickets, selectedQueueIds], 0);

  const pausedCount = useLiveQuery(() => {
    return queryTickets({ status: 'paused', showAll: showAllTickets, userId: user?.id, selectedQueueIds }).count()
  }, [showAllTickets, selectedQueueIds], 0);

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Verifica se a tecla Ctrl está pressionada
      if (event.ctrlKey && event.key === 'x') {
        event.preventDefault();
        setNewTicketModalOpen(true);
      }
    };

    // Adiciona o event listener para o atalho do teclado
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tab !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    // if (ticket !== undefined && ticket.uuid !== undefined) {
    //   history.push(`/tickets/${ticket.uuid}`);
    // }
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id);
    setSelectedUsers(users);
  };

  const handleSelectedOrigins = (origins) => {
    setSelectedOrigins(origins);
  }

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketFasterModal
        modalOpen={newTicketModalOpen}
        onClose={(ticket) => {

          handleCloseOrOpenTicket(ticket);
        }}
      />
      <Box className={classes.ticketTabsContainer}>
        <Paper elevation={0} square className={classes.tabsHeader}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="icon label tabs example"
          >
            <Tab
              value={"open"}
              icon={
                <Badge
                  className={classes.badge}
                  badgeContent={openCount}
                  color="primary"
                >
                  <Tooltip title={i18n.t("tickets.tabs.open.title")}>
                    <MoveToInboxIcon />
                  </Tooltip>
                </Badge>
              }
              classes={{ root: classes.tab }}
            />
            <Tab
              value={"pending"}
              icon={
                <Badge
                  className={classes.badge}
                  badgeContent={pendingCount}
                  color="secondary"
                >
                  <Tooltip title={i18n.t("tickets.tabs.pending.title")}>
                    <HistoryIcon />
                  </Tooltip>
                </Badge>
              }
              classes={{ root: classes.tab }}
            />
            <Tab
              value={"closed"}
              icon={
                <Tooltip title={i18n.t("tickets.tabs.closed.title")}>
                  <CheckBoxIcon />
                </Tooltip>
              }
              classes={{ root: classes.tab }}
            />
            <Tab
              value={"paused"}
              icon={
                <Badge
                  className={classes.badge}
                  badgeContent={pausedCount}
                  color="secondary"
                >
                  <Tooltip title={i18n.t("tickets.tabs.paused.title")}>
                    <PauseIcon />
                  </Tooltip>
                </Badge>
              }
              classes={{ root: classes.tab }}
            />
            <Tab
              value={"search"}
              icon={
                <Tooltip title={i18n.t("tickets.tabs.search.title")}>
                  <SearchIcon />
                </Tooltip>
              }
              classes={{ root: classes.tab }}
            />
          </Tabs>
        </Paper>

        {['open', 'pending', 'closed', 'paused'].includes(tab) && (
          <Paper square elevation={0} className={classes.ticketOptionsBox}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setNewTicketModalOpen(true)}
            >
              {i18n.t("ticketsManager.buttons.newTicket")}
            </Button>
            <Can
              role={user.profile}
              perform="tickets-manager:showall"
              yes={() => (
                <FormControlLabel
                  label={i18n.t("tickets.buttons.showAll")}
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={showAllTickets}
                      onChange={() =>
                        setShowAllTickets((prevState) => !prevState)
                      }
                      name="showAllTickets"
                      color="primary"
                    />
                  }
                />
              )}
            />
            <TicketsQueueSelect
              style={{ marginLeft: 6 }}
              selectedQueueIds={selectedQueueIds}
              userQueues={user?.queues}
              onChange={(values) => setSelectedQueueIds(values)}
            />
          </Paper>
        )}

        <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
          <TicketsListIndexed
            status="open"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
          />
        </TabPanel>

        <TabPanel value={tab} name="pending" className={classes.ticketsWrapper}>
          <TicketsListIndexed
            status="pending"
            showAll={true}
            selectedQueueIds={selectedQueueIds}
          />
        </TabPanel>

        <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
          <TicketsListIndexed
            status="closed"
            showAll={true}
            selectedQueueIds={selectedQueueIds}
          />
        </TabPanel>

        <TabPanel value={tab} name="paused" className={classes.ticketsWrapper}>
          <TicketsListIndexed
            status="paused"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
          />
        </TabPanel>

        <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
          <Paper square elevation={0} className={classes.ticketOptionsBox}>
            <div className={classes.serachInputWrapper}>
              <SearchIcon className={classes.searchIcon} />
              <InputBase
                className={classes.searchInput}
                inputRef={searchInputRef}
                placeholder={i18n.t("tickets.search.placeholder")}
                type="search"
                onChange={handleSearch}
              />
            </div>
          </Paper>
          <TagsFilter onFiltered={handleSelectedTags} />
          {profile === "admin" && (
            <UsersFilter onFiltered={handleSelectedUsers} />
          )}
          <TicketOriginsFilter onFiltered={handleSelectedOrigins} />
          <TicketsList
            searchParam={searchParam}
            showAll={true}
            tags={selectedTags}
            users={selectedUsers}
            origins={selectedOrigins}
            selectedQueueIds={searchQueueIds}
          />
        </TabPanel>
      </Box>
    </Paper>
  );
};

export default TicketsManagerTabs;
