import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import db, { syncData, pruneData, saveTicket, saveMessage } from "../../services/db";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import BackdropLoading from "../../components/BackdropLoading";
import * as Sentry from "@sentry/react";

const TicketStore = ({ children }) => {
	const [socket, setSocket] = useState();
	const [isSocketConnected, setIsSocketConnected] = useState(false);
	const [isSyncingData, setIsSyncingData] = useState(false);
	const [currentTicket, setCurrentTicket] = useState({});
	const { user } = useContext(AuthContext);
	const { pathname } = useLocation();

	useEffect(() => {
		async function synchronizeDatabase() {
			setIsSyncingData(true);

			try {
				await syncData();
				await pruneData();
			} finally {
				setIsSyncingData(false);
			}
		}

		if (user?.id) {
			const companyId = localStorage.getItem("companyId");
			const socket = socketConnection({ companyId });

			socket.onAny((event, data) => {
				Sentry.addBreadcrumb({
					category: "socket",
					message: event,
					level: "info",
					data: data,
					type: "socket"
				});
			});

			socket.on("connect", () => {
				setIsSocketConnected(true);
				socket.emit("joinTickets", "open");
				socket.emit("joinTickets", "pending");
				socket.emit("joinTickets", "closed");
				socket.emit("joinTickets", "paused");
				socket.emit("joinNotification");
			});

			socket.on("disconnect", () => {
				setIsSocketConnected(false);
			});

			socket.on(`company-${companyId}-ticket`, async data => {
				if (["update", "updateAck"].includes(data.action)) {
					await saveTicket(data.ticket);
				}

				if (data.action === "updateUnread") {
					await saveTicket({ id: data.ticketId, unreadMessages: 0 });
				}
			});

			socket.on(`company-${companyId}-appMessage`, async data => {
				if (data.action === "create") {
					await saveTicket(data.ticket);
					await saveMessage(data.message);
				}

				if (data.action === "update") {
					await saveMessage(data.message);
				}
			});

			socket.on(`company-${companyId}-contact`, async data => {
				if (data.action === "update") {
					const { contact } = data;
					const ticket = await db.tickets.get({ contactId: contact.id });
					if (ticket) {
						await saveTicket({ id: ticket.id, contact });
					}
				}
			});

			setSocket(socket);
			synchronizeDatabase();

			return () => {
				socket.disconnect();
			};
		}
	}, [user]);

	useEffect(() => {
		async function fetchCurrentTicket() {
			let currentTicket;

			const ticketUUID = /\/tickets\/(.+)/.exec(pathname)?.[1];

			if (ticketUUID) {
				currentTicket = await db.tickets.where("uuid").equals(ticketUUID).first();
			}

			setCurrentTicket(currentTicket || {});
		}

		fetchCurrentTicket();
	}, [pathname]);


	useEffect(() => {
		if (!isSocketConnected) return;
		if (!currentTicket.id) return;
		if (!socket) return;

		socket.emit("joinChatBox", currentTicket.id.toString());

		return () => {
			socket.emit("leaveChatBox", currentTicket.id.toString());
		}
	}, [currentTicket.id, socket, isSocketConnected]);

	if (isSyncingData) {
		return <BackdropLoading />;
	} else {
		return children;
	}
};

export { TicketStore };
