import React, { useState, useEffect, createContext } from "react";
import { useHistory } from "react-router-dom";

const TicketsContext = createContext();

const TicketsContextProvider = ({ children }) => {
	const [tickets, setTickets] = useState({});
	const [currentTicket, setCurrentTicket] = useState({ id: null, code: null });
	const history = useHistory();

	useEffect(() => {
		if (currentTicket.id !== null) {
				history.push(`/tickets/${currentTicket.uuid}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTicket])

	const openNextOpenTicket = () => {
		const openTickets = tickets.open || [];

		let nextTicket;

		if (openTickets.length > 0) {
			const currentIndex = openTickets.findIndex(
				ticket => ticket.id === currentTicket.id
			);

			if (currentIndex > -1) {
				nextTicket = openTickets[currentIndex + 1] || openTickets[currentIndex - 1];
			}
		}

		if (nextTicket) {
			setCurrentTicket(nextTicket);
		} else {
			setCurrentTicket({ id: null, code: null });
			history.push("/tickets");
		}
	}

	const setStatusTickets = (status, tickets) => {
		setTickets(prev => ({
			...prev,
			[status]: tickets
		}));
	}

	return (
		<TicketsContext.Provider
			value={{ 
				currentTicket, 
				setCurrentTicket, 
				openNextOpenTicket,
				setStatusTickets,
			}}
		>
			{children}
		</TicketsContext.Provider>
	);
};

export { TicketsContext, TicketsContextProvider };
