import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n";

export function TicketOriginsFilter({ onFiltered }) {
  const [selected, setSelected] = useState([]);

  const handleChange = async (_, value) => {
    setSelected(value);
    onFiltered(value.map((v) => v.id));
  };

  const options = [
    { id: "proactive", name: i18n.t("ticketsFilters.origins.proactive") },
    { id: "reactive", name: i18n.t("ticketsFilters.origins.reactive") },
  ]

  return (
    <Box style={{ padding: "0px 10px 10px" }}>
      <Autocomplete
        multiple
        size="small"
        options={options}
        value={selected}
        onChange={handleChange}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option?.id === value?.id}
        renderTags={(value, getProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              style={{
                backgroundColor: "#bfbfbf",
                textShadow: "1px 1px 1px #000",
                color: "white",
              }}
              label={option.name}
              {...getProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={i18n.t("ticketsFilters.origins.placeholder")}
          />
        )}
      />
    </Box>
  );
}
