import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";

const TicketsSkeleton = ({ style }) => {
	return (
		<div style={style}>
			<ListItem dense>
				<ListItemAvatar>
					<Skeleton animation="wave" variant="circle" width={50} height={50} />
				</ListItemAvatar>
				<ListItemText
					style={{ marginLeft: 8 }}
					primary={<Skeleton animation="wave" height={20} width={80} />}
					secondary={
						<Box component="span" display="flex" flexDirection="column">
							<Skeleton animation="wave" height={20} width={230} />
							<Skeleton animation="wave" height={20} width={150} />
						</Box>
					}
				/>
			</ListItem>
			<Divider variant="inset" />
		</div>
	);
};

export default TicketsSkeleton;
