import React, { useState, useEffect } from "react";
import { Badge, withStyles } from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
    TableBody,
    TableRow,
    TableCell,
    Table,
    TableHead,
    Paper,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: "center",
    },
    buttonProgress: {
        color: green[500],
    },
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: props => {
            switch (props.status) {
                case 'RED':
                    return '#f44336';
                case 'YELLOW':
                    return '#ffeb3b';
                case 'GREEN':
                    return '#4caf50';
                default:
                    return '#999';
            }
        },
        color: props => {
            switch (props.status) {
                case 'RED':
                    return '#f44336';
                case 'YELLOW':
                    return '#ffeb3b';
                case 'GREEN':
                    return '#4caf50';
                default:
                    return '#999';
            }
        },
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        width: 10,
        height: 10,
        borderRadius: '50%',
        border: '2px solid white',
    },
}))(Badge);

// Componente que utiliza o StyledBadge
function StatusBadge({ status }) {
    return (
        <StyledBadge
            status={status}
            variant="dot"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        />
    );
}

const ConnectionsMeta = () => {
    const classes = useStyles();

    const [whatsApps, setWhatsApps] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchWhatsApps();
    }, []);

    const fetchWhatsApps = async () => {
        try {
            const { data } = await api.get("/officialWhatsapp/");
            setWhatsApps(data);
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    }

    // const handleOpenWhatsAppModal = () => {
    //     setSelectedWhatsApp(null);
    //     setWhatsAppModalOpen(true);
    // };

    // const handleEditWhatsApp = whatsApp => {
    //     setSelectedWhatsApp(whatsApp);
    //     setWhatsAppModalOpen(true);
    // };

    return (
        <MainContainer>
            {/* <WhatsAppModal
                open={whatsAppModalOpen}
                onClose={handleCloseWhatsAppModal}
                whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
            /> */}
            <MainHeader>
                <Title>Conexões Meta</Title>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Número
                            </TableCell>
                            <TableCell align="center">
                                Tier
                            </TableCell>
                            <TableCell align="center">
                                Status
                            </TableCell>
                            <TableCell align="center">
                                BM
                            </TableCell>
                            <TableCell align="center">
                                Status Conta
                            </TableCell>
                            <TableCell align="center">
                                Status Docker
                            </TableCell>
                            <TableCell align="center">
                                Status Operação
                            </TableCell>
                            {/* <Can
                                role={user.profile}
                                perform="connections-page:editOrDeleteConnection"
                                yes={() => (
                                    <TableCell align="center">
                                        {i18n.t("connections.table.actions")}
                                    </TableCell>
                                )}
                            /> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowSkeleton />
                        ) : (
                            <>
                                {whatsApps?.length > 0 &&
                                    whatsApps.map(whatsApp => (
                                        <TableRow key={whatsApp.id}>
                                            <TableCell align="center">{whatsApp.phoneNumber}</TableCell>
                                            <TableCell align="center">{whatsApp.messagingLimit}</TableCell>
                                            <TableCell align="center">
                                                <StatusBadge status={whatsApp.qualityScore} />
                                            </TableCell>
                                            <TableCell align="center">{whatsApp.businessName}</TableCell>
                                            <TableCell align="center">{whatsApp.accountStatus}</TableCell>
                                            <TableCell align="center">{whatsApp.dockerStatus}</TableCell>
                                            <TableCell align="center">{whatsApp.operatingStatus}</TableCell>
                                            {/* <Can
                                                role={user.profile}
                                                perform="connections-page:editOrDeleteConnection"
                                                yes={() => (
                                                    <TableCell align="center">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleEditWhatsApp(whatsApp)}
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    </TableCell>
                                                )}
                                            /> */}
                                        </TableRow>
                                    ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default ConnectionsMeta;
