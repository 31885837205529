import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";

import clsx from "clsx";

import { Paper, makeStyles } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInputCustom/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtonsCustom";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { TagsContainer } from "../TagsContainer";
import useWhatsApp from "../../hooks/useWhatsApp";
import db from "../../services/db";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {
  const { ticketId } = useParams();
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const ticket = useLiveQuery(async () => {
    let ticket;

    if (ticketId) {
      ticket = await db.tickets.where("uuid").equals(ticketId).first();
    }

    return ticket || {};
  }, [ticketId], {});

  const whatsApp = useWhatsApp(ticket?.whatsappId);

  const loading = !ticket?.id;
  const contact = ticket?.contact || {};

  useEffect(() => {
    if (ticket.id) {
      api.post(`/tickets/${ticket.id}/read`);
    }
  }, [ticket.id]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const renderTicketInfo = () => {
    if (ticket.user !== undefined) {
      return (
        <TicketInfo
          contact={contact}
          ticket={ticket}
          onClick={handleDrawerOpen}
        />
      );
    }
  };

  const renderMessagesList = () => {
    return (
      <>
        <MessagesList
          ticket={ticket}
          ticketId={ticket.id}
          isGroup={ticket.isGroup}
        ></MessagesList>
        {(ticket.whatsapp?.isOfficial || whatsApp?.status === 'CONNECTED') &&
          ticket.status === 'open' &&
          ticket.queue?.name !== 'BOT' && (
            <MessageInput ticketId={ticket.id} ticketStatus={ticket.status} />
          )}
      </>
    );
  };

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          {renderTicketInfo()}
          <TicketActionButtons ticket={ticket} />
        </TicketHeader>
        <Paper>
          {ticket.queue?.name !== 'BOT' && (
            <TagsContainer ticket={ticket} />
          )}
        </Paper>
        <ReplyMessageProvider>{renderMessagesList()}</ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
        ticket={ticket}
      />
    </div>
  );
};

export default Ticket;
